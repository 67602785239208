<template>
  <input
      type="text"
      :value="asString(modelValue)"
      @change="update($event.target.value)"
      placeholder="00:00"
  />
</template>

<script>
import {formatMinutesAsString} from "../format";

export default {
  name: 'TimeInput',
  props: ['modelValue', 'mode',], // TODO validation: mode string enum (h/m/t), model int/float/string
  emits: ['update:modelValue'],
  methods: {
    update(value) {
      const minutes = this.parseToMinutes(value)
      if (!isNaN(minutes) && minutes !== null) {
        this.$emit('update:modelValue', this.asOutput(minutes))
      }
      // else emit error? hasError attr?
    },
    parseToMinutes(value) {
      // if obsahuje `:` je to cas, treba previest
      // if cislo, su to hodiny (minuty?)
      // pozr na to, ze ciarku treba dat do<>, pouzi funkciu `parseDecimalNumber` (balicek parse-decimal-number)
      // else error
      if (typeof value === 'string' && value.includes(':')) {
        let h, m
        [h, m] = value.split(':')

        // debugger  // eslint-disable-line no-debugger

        return (+h) * 60 + (+m)
      }
      if (value === null || isNaN(value)) {
        return null
      }
      // when the mode is 'minutes', treat numeric value as minutes, as hours otherwise
      return this.mode === 'm' ? +value : Math.round(value * 60)
    },
    asOutput(minutes) {
      // mode can be 'h' (hours, default), 'm' (minutes), 't' (time string)
      if (this.mode === 'm') {
        return minutes
      }
      if (this.mode === 't') {
        return formatMinutesAsString(minutes)
      }
      // output as hours by default
      return minutes !== null ? minutes / 60 : null
    },
    asString(value) {
      const minutes = this.parseToMinutes(value)
      return minutes !== null && !isNaN(minutes) ? formatMinutesAsString(minutes) : null
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
