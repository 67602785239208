function formatMinutesAsString(minutes) {
  const h = Math.floor(minutes / 60)
  const m = Math.floor(minutes % 60) // fakt divne, ked to tu nie je, nedostavam cele cisla !!
  const m0 = m < 10 ? '0' : ''
  const h0 = h < 10 ? '0' : ''
  return `${h0}${h}:${m0}${m}`
}

export {
  formatMinutesAsString,
}
