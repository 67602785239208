<template>
  <div>
    <slot name="default"></slot>
    <time-input :modelValue="time" @update:modelValue="$emit('update:time', $event)"/>

    <input type="number" placeholder="Sadzba" :value="rate" @change="$emit('update:rate', $event.target.value ? +$event.target.value : null)"/>
    <input type="text" :placeholder="placeholder" :value="description" @change="$emit('update:description', $event.target.value)"/>

    <slot name="remove" :remove="remove">
      <button title="Odstranit sadzbu/riadok" @click="remove">✖</button>
    </slot>
  </div>

</template>

<script>
import TimeInput from "./TimeInput";

export default {
  name: 'OneRow',
  components: {TimeInput},
  props: ['placeholder', 'time', 'rate', 'description',],
  emits: ['update:time', 'update:rate', 'update:description', 'remove'],
  methods: {
    remove() {
      this.$emit('remove')
    },
  },
}
</script>
