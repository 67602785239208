<template>

  <div class="fakapp">


    <one-row
        v-model:time="base.time"
        v-model:rate="base.rate"
        v-model:description="base.descr"
        @remove="reset"
        placeholder="::"
    >
      <template #default>
        ➕
      </template>
      <template #remove="{ remove }">
        <button title="Reset" @click="remove">❌</button>
      </template>
    </one-row>


    <div v-for="(row, i) in subtract" :key="i">
      <one-row
          v-model:time="row.time"
          v-model:rate="row.rate"
          v-model:description="row.descr"
          @remove="removeSub(i)"
          :placeholder="`$${i+1}`"
      >
        ➖
      </one-row>
    </div>

    <p>
      <button @click="addSub">➖ Odpočet hodín (odlišná sadzba)</button>
    </p>

    <div v-for="(row, i) in extra" :key="i">
      <one-row v-model:time="row.time" v-model:rate="row.rate" v-model:description="row.descr" @remove="removeExtra(i)">
        ➕
      </one-row>
    </div>
    <p>
      <button @click="addExtra">➕ Pridaj položku</button>
    </p>

    <div v-if="total" class="result">
      <table>
        <thead>
        <tr>
          <th>Položka</th>
          <th class="num">Čas</th>
          <th class="num" title="Merné jednotky, hodiny">M.j. (hod.)</th>
          <th class="num">Sadzba</th>
          <th class="num">Cena</th>
        </tr>
        </thead>
        <tr v-for="(row,i) in rows" :key="i">
          <td>{{ row.descr || `$${i + 1}` }}</td>
          <td class="num">{{ row.timeStr }}</td>
          <td class="num">{{ row.time.toFixed(decimals.time) }}</td>
          <td class="num">{{ row.rate }}</td>
          <td class="num">{{ row.price.toFixed(decimals.price) }}</td>
        </tr>
        <!--
        <tr>
          <td colspan="5">--------------------------------------------------------------------</td>
        </tr>
        -->
        <tr class="total">
          <td>
            Spolu ::
          </td>
          <th class="num">{{ total.timeStr }}</th>
          <td class="num">{{ total.time.toFixed(decimals.time) }}</td>
          <td></td>
          <th class="num">{{ total.price.toFixed(decimals.price) }}</th>
        </tr>
      </table>


      <div class="result textual">

        <div v-for="(row,i) in rows" :key="i">
          {{ row.time.toFixed(decimals.time) }} * {{ row.rate }} = {{ row.price.toFixed(decimals.price) }} ({{ row.timeStr }},
          <span v-if="row.descr">{{ row.descr }}</span>)
        </div>

        == {{ total.price.toFixed(decimals.price) }} ({{ total.timeStr }})
      </div>

      <!--
      <p>
        <br>
        <br>
        <br>
        <button @click="reset">❌ Reset</button>
      </p>
      -->
    </div>

    <div class="help">
      <a href="#" @click.prevent="toggleHelp" class="toggle">
        <span v-if="showHelp" title="Zavriet" style="color:red">x</span>
        <span v-else title="Pomoc" style="color:blue">?</span>
      </a>
      <div v-if="showHelp" class="result sans">
        <p>
          Aplikácia umožňuje spočítať položky k fakturácii, pokiaľ vieme celkový čas a máme viacero sadzieb.
          Tiež umožňuje klasické sčítanie položiek.
        </p>
        <ol>
          <li>Zadaj celkový čas a default hodinovú sadzbu.</li>
          <li>Pridaj položky so špecifickou sadzbou pre odpočet z celkového času.</li>
          <li>Čas za špecifické sadzby sa odpočíta z celkového času.</li>
          <li>Zvyšok je vynásobený default sadzbou.</li>
          <li>Ostatné položky sú pripočítané.</li>
        </ol>
      </div>
    </div>

    <div class="disclosure sans">
      <small>
        Dáta sú uložené v local storage, nikam sa neposielajú.
      </small>
    </div>

  </div>

  <footer class="signature sans">
    <a class="mono" href="https://dakujem.dev" title="Autorom chýb je dakujem.">dakujem<span>.dev</span></a>
  </footer>

</template>

<script>
import OneRow from "./components/OneRow";
import {formatMinutesAsString} from "./format";

const row = () => ({
  time: null,
  rate: null,
  descr: null,
})
const reset = () => ({
  base: row(),
  subtract: [],
  extra: [],
})

export default {
  name: 'FaCalc',
  components: {
    OneRow,
  },
  data() {
    const defaults = reset()
    const cd = localStorage.calcdata
    cd && Object.assign(defaults, JSON.parse(cd))
    return {
      ...defaults,
      help: null,
    }
  },
  mounted() {
    if (this.rows.length > 0) {
      this.introOff()
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, reset())
    },
    addSub() {
      this.subtract.push(row())
    },
    addExtra() {
      this.extra.push(row())
    },
    removeSub(i) {
      this.subtract.splice(i, 1)
    },
    removeExtra(i) {
      this.extra.splice(i, 1)
    },
    toggleHelp() {
      this.help = !(this.help ?? true)
    },
    introOff() {
      this.help = this.help ?? false
    },
  },
  computed: {
    rows() {
      localStorage.calcdata = JSON.stringify(this.$data) // it is a hack to place this inside the computed prop, i think
      // if (!this.base.time || !this.base.rate) {
      //   return []
      // }

      const valid = (row) => row.time !== null && +row.time !== 0 && row.rate !== null && +row.rate !== 0
      let baseTime = this.base.time ?? 0
      const baseRate = this.base.rate ?? 0
      const rows = []
      if (baseTime && baseRate) {
        for (const row of this.subtract) {
          // console.log(row)
          if (valid(row)) {
            baseTime -= row.time
            rows.push({
              ...row,
              timeStr: formatMinutesAsString(row.time * 60),
              price: (row.rate * row.time),
            })
          }
        }

        rows.push({
          time: baseTime,
          timeStr: formatMinutesAsString(baseTime * 60),
          rate: baseRate,
          price: (baseTime * baseRate),
          descr: this.base.descr || ('Ostatne' + ' ::')
        })
      }

      // baseTime = this.base.time ?? 0
      for (const row of this.extra) {
        if (valid(row)) {
          // baseTime += row.time
          rows.push({
            ...row,
            timeStr: formatMinutesAsString(row.time * 60),
            price: (row.rate * row.time),
          })
        }
      }

      if (rows.length > 0) {
        this.introOff()
      }

      return rows
    },
    total() {
      if (this.rows.length === 0) {
        return null
      }
      const time = this.rows.map((row) => row.time).reduce((c, v) => c + v, 0)
      const price = this.rows.map((row) => row.price).reduce((c, v) => c + v, 0)
      return {
        time,
        timeStr: formatMinutesAsString(time * 60),
        price: price,
      }
    },
    decimals() {
      return {
        time: 4,
        price: 2,
      }
    },
    showHelp() {
      return this.help ?? true
    },
  },
}
</script>

<style>
.fakapp {
  position: relative;
  margin: auto;
  width: 700px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  padding: 4rem;
  background: white;
  border-radius: 2rem;
}

.result {
  margin-top: 4rem;
}

.textual {
  margin-top: 8rem;
}

.num {
  text-align: right;
}

table .total > * {
  border-top: 1px dashed;
  padding-top: 1rem;
}

table thead > tr > * {
  border-bottom: 1px dashed;
  padding-bottom: 1rem;
}

.help {
  margin-top: 4rem;
}

.help .toggle {
  float: right;
}
.help a.toggle:hover {
  text-decoration: none;
}

.disclosure {
  position: absolute;
  bottom: -2rem;
}
.signature {
  position: relative;
  right: 1rem;
  bottom: 1rem;
  font-size: 0.8rem;
  text-align: right;
  margin-top: 150px;
}

.signature span {
  color: #99a487;
}

</style>
